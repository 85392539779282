.logo {
  width: 20%;
  margin-top: 0.5vh;
}

.logoWorld {
  width: 15%;
  margin-top: 0.5vh;
}

.logoWorld img {
  width: 20% !important;
  margin-top: 0.5vh;
}

.collapseMenu {
  display: flex;
  flex-direction: column;
  padding: 0;
  border: solid 0.3px rgb(182, 179, 179);
  background-color: rgb(182, 179, 179);
  text-align: start;
  margin: 0;
  padding: 1vh 0;
}

.collapseMenu a {
  font-size: 1rem;
  margin: 0.3vh 0.3vw;
  text-decoration: none;
}

.collapseAll {
  display: none;
}

.collapseMenu a {
  margin: 1vh 0;
  padding-left: 4.5vw;
  font-size: 1rem;
  color: white;
}

.footerBase {
  background-color: #383838;
  min-width: 100%;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 5vw;
  justify-content: center;
  color: wheat;
}

.info-background{
  background-color: #383838;
}

.google-reviews-tagembeded {
  width:100%;
  height:100%;
  overflow: auto;
}

@media (max-width: 375px) {
  .logo img {
    width: 50% !important;
    margin-top: 0.5vh;
  }
}

@media (max-width: 870px) {
  .logo {
    width: 50% !important;
  }

  .logoWorld img {
    width: 20% !important;
    margin-top: 0.5vh;
  }
  .collapseAll {
    display: block !important;
    display: flex;
    flex-direction: column;
  }
  .footerBase {
    font-size: 0.7rem;
  }
}
